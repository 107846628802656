<template>
  <div>
    <el-dialog :visible.sync="isModalVisible" top="4%" width="60%">
      <p class="title">Add/Modify Rate</p>
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        @submit.prevent.native="checkEnter"
        label-position="left"
        class="main-form"
      >
        <div class="grid-container">
          <div class="left-side">
            <el-form-item prop="amount" label="Amount">
              <el-input
                placeholder="Amount"
                v-model="formData.amount"
              ></el-input>
            </el-form-item>
            <el-form-item prop="rate" label="Rate">
              <el-input placeholder="Amount" v-model="formData.rate"></el-input>
            </el-form-item>
            <el-form-item prop="is_promo" label="Rate Type">
              <el-select v-model="formData.is_promo" filterable>
                <el-option label="Promo" :value="1"></el-option>
                <el-option label="Non-Expiry" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="description" label="Description">
              <el-input
                placeholder="Short Description"
                v-model="formData.description"
              ></el-input>
            </el-form-item>
          </div>
          <div class="right-side">
            <el-form-item prop="user_type_rate" label="Account Type">
              <el-select v-model="formData.user_type_rate" filterable>
                <el-option label="Individual" :value="1"></el-option>
                <el-option label="Corporate" :value="2"></el-option>
                <el-option label="Both" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="expiry_period"
              label="Expiry Period"
              v-if="formData.is_promo == 1"
            >
              <el-select v-model="formData.expiry_period" filterable>
                <el-option
                  label="Indefinite"
                  :value="0"
                  v-if="formData.user_type_rate == 1"
                ></el-option>
                <el-option label="Monthly Bundle" :value="1"></el-option>
                <el-option label="Quarterly Bundle" :value="2"></el-option>
                <el-option label="Half Year Bundle" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="service_type" label="Service Type">
              <el-select v-model="formData.service_type" filterable>
                <el-option label="SMS" :value="1"></el-option>
                <el-option label="Voice" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="country" label="Country Code">
              <el-select
                v-model="formData.country"
                filterable
                placeholder="Select country"
              >
                <el-option
                  v-for="(country, index) in availableCountries"
                  :key="index"
                  :label="country.label"
                  :value="country.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="form_input_container">
          <el-button
            style="margin: 0 auto"
            type="danger"
            id="cancelinput"
            @click="openModal('ruleForm')"
          >
            Submit
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- Modal To Confirm -->
    <el-dialog title="Confirm" :visible.sync="dialogVisible" width="60%">
      <span>Send the following rate</span>
      <div>
        <el-table :data="[formData]">
          <el-table-column label="Amount" width="150"
            ><span>{{ formData.amount }}</span></el-table-column
          >
          <el-table-column label="Rate" width="150"
            ><span>{{ formData.rate }}</span></el-table-column
          >
          <el-table-column label="Promo Type" width="150">
            <span>{{
              formData.is_promo == 1 ? "Promo" : "Non-Expiry"
            }}</span></el-table-column
          >
          <el-table-column label="Description" width="150">
            <span>{{ formData.description }}</span></el-table-column
          >
          <el-table-column label="Expiry Period" width="150">
            <span v-if="formData.expiry_period == 0">Indefinite</span>
            <span v-if="formData.expiry_period == 1">Monthly Bundle</span>
            <span v-if="formData.expiry_period == 2">Quarterly Bundle</span>
            <span v-if="formData.expiry_period == 3">Half Year Bundle</span>
          </el-table-column>
          <el-table-column label="User Type" width="120">
            <span>
              {{
                formData.user_type_rate == 1
                  ? "Individual"
                  : formData.user_type_rate == 2
                  ? "Corporate"
                  : "Both"
              }}
            </span>
          </el-table-column>
          <el-table-column label="Service Type" width="150">
            <span>{{
              formData.service_type == 1 ? "SMS" : "Voice"
            }}</span></el-table-column
          >
        </el-table>
      </div>
      <template slot="footer">
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="sendRates" :loading="buttonloading"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
  import Storage from "../../../../services/storage";
  import { francoCountries } from "@/state/modules/auth";
  var storage = new Storage();
  export default {
    data() {
      return {
        rules: {
          amount: [
            {
              required: true,
              message: "Amount is required.",
              trigger: "blur",
            },
          ],
          rate: [
            { required: true, message: "Enter a valid rate", trigger: "blur" },
          ],
          is_promo: [
            {
              required: true,
              message: "Enter a valid rate type",
              trigger: "blur",
            },
          ],
          service_type: [
            {
              required: true,
              message: "Enter a valid service type",
              trigger: "blur",
            },
          ],
          country: [
            {
              required: true,
              message: "Enter a valid country",
              trigger: "blur",
            },
          ],
          user_type_rate: [
            {
              required: true,
              message: "Enter a valid account type",
              trigger: "blur",
            },
          ],
          description: [
            {
              required: false,
              message: "Enter a valid description",
              trigger: "blur",
            },
          ],
        },
        dialogVisible: false,
        buttonloading: false,
        countries: [
          {
            value: "GH",
            label: "Ghana",
          },
          {
            value: "CI",
            label: "Cote d'Ivoire",
          },
          {
            value: "SN",
            label: "Senegal",
          },
          {
            value: "BJ",
            label: "Benin",
          },
          {
            value: "GN",
            label: "Guinea",
          },
          {
            value: "TG",
            label: "Togo",
          },
          {
            value: "NE",
            label: "Niger",
          },
        ],
      };
    },

    props: {
      showAddModifyRate: {
        type: Boolean,
        required: true,
      },
      formData: {
        type: Object,
        default: () => {
          return {
            amount: "",
            rate: "",
            is_promo: "",
            service_type: "",
            user_type_rate: "",
            description: "",
            expiry_period: "",
            country: "",
          };
        },
      },
    },
    computed: {
      isModalVisible: {
        get() {
          return this.showAddModifyRate;
        },
        set(value) {
          this.$emit("closeModal");
        },
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
      availableCountries() {
        return this.countries.filter((country) => {
          // if disallowed user show only the country of the user
          if (this.disallowedUsers) {
            // return all the countries in francoCountries
            return francoCountries.includes(country.value);
          }
          return true;
        });
      },
    },
    methods: {
      closeAddModifyRate() {
        this.$emit("closeModal");
        return;
      },
      sendRates() {
        this.buttonloading = true;
        let payload = {
          amount: this.formData.amount,
          rate: this.formData.rate,
          is_promo: this.formData.is_promo,
          service_type: this.formData.service_type,
          user_type_rate: this.formData.user_type_rate,
          description: this.formData.description,
          expiry_period:
            this.formData.is_promo == 1 ? this.formData.expiry_period : 0,
          id: this.formData.id || null,
          country: this.formData.country,
        };
        this.$store
          .dispatch("admin/rates/sendSMSVoiceRate", payload)
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message: "Rate sent successfully",
                type: "success",
              });
              this.buttonloading = false;
              this.dialogVisible = false;
              this.$emit("closeModal");
            }
          })
          .catch((error) => {
            this.$notify({
              message: "Error sending rate",
              type: "error",
            });
          })
          .finally(() => {
            this.buttonloading = false;
          });
      },
      openModal(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.dialogVisible = true;
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  .grid-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .grid-container * {
    display: inline-block;
    width: 90%;
  }
</style>
